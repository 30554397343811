@import "./variables/variables.css";
@import "./typography.css";
@import "./elements/index.css";

* {
	box-sizing: border-box;
	font-family:
		Roboto,
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Oxygen,
		Ubuntu,
		Cantarell,
		"Open Sans",
		"Helvetica Neue",
		sans-serif;
}

html,
body {
	min-height: 100%;
	background: var(--background-color);
	color: var(--primary-text-color);
	margin: 0;
}

p,
span {
	padding: 0;
	margin: 0;
}

/* Global animations */
@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
