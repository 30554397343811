:root {
	/* Elements spacing. */
	--space-0_5: 2px;
	--space-1: 4px;
	--space-2: 8px;
	--space-3: 12px;
	--space-4: 16px;
	--space-5: 20px;
	--space-6: 24px;
	--space-7: 28px;
	--space-8: 32px;
	--space-9: 36px;
	--space-10: 40px;
	--space-11: 44px;
	--space-12: 48px;
	--space-13: 52px;
	--space-14: 56px;
	--space-15: 60px;
	--space-16: 64px;

	--border-radius-sm: 4px;
	--border-radius-md: 8px;
	--border-radius-lg: 12px;
	--border-radius-xl: 16px;

	--icon-font-size-sm: 16px;
	--icon-font-size-md: 20px;
	--icon-font-size-lg: 24px;

	/* Page spacing. */
	--page-vertical-padding: var(--space-8);
	--page-horizontal-padding: var(--space-10);
	--page-content-max-width: 1800px;
	--page-content-max-width-with-padding: calc(var(--page-content-max-width) + 2 * var(--page-horizontal-padding));

	--header-horizontal-padding: var(--page-horizontal-padding);
	--dialog-width: 500px;

	--box-shadow-sm: 0px 2px 6px 2px #00000026;
}
