:root {
	/* stylelint-disable number-max-precision */

	--display-large-font-size: 3.5625rem;
	--display-large-line-height: 4rem;
	--display-large-font-weight: 400;
	--display-large-letter-spacing: -0.015625rem;

	--display-medium-font-size: 2.8125rem;
	--display-medium-line-height: 3.25rem;
	--display-medium-font-weight: 400;
	--display-medium-letter-spacing: 0rem;

	--display-small-font-size: 2.25rem;
	--display-small-line-height: 2.75rem;
	--display-small-font-weight: 400;
	--display-small-letter-spacing: 0rem;

	--headline-large-font-size: 2rem;
	--headline-large-line-height: 2.5rem;
	--headline-large-font-weight: 400;
	--headline-large-letter-spacing: 0rem;

	--headline-medium-font-size: 1.75rem;
	--headline-medium-line-height: 2.25rem;
	--headline-medium-font-weight: 400;
	--headline-medium-letter-spacing: 0rem;

	--headline-small-font-size: 1.5rem;
	--headline-small-line-height: 2rem;
	--headline-small-font-weight: 500;
	--headline-small-letter-spacing: 0rem;

	--title-large-font-size: 1.375rem;
	--title-large-line-height: 1.75rem;
	--title-large-font-weight: 400;
	--title-large-letter-spacing: 0rem;

	--title-medium-font-size: 1rem;
	--title-medium-line-height: 1.5rem;
	--title-medium-font-weight: 500;
	--title-medium-letter-spacing: 0.009375rem;

	--title-small-font-size: 0.875rem;
	--title-small-line-height: 1.25rem;
	--title-small-font-weight: 500;
	--title-small-letter-spacing: 0.00625rem;

	--label-large-prominent-font-size: 1.125rem;
	--label-large-prominent-line-height: 1.25rem;
	--label-large-prominent-font-weight: 500;
	--label-large-prominent-letter-spacing: 0.006rem;

	--label-large-font-size: 0.875rem;
	--label-large-line-height: 1.25rem;
	--label-large-font-weight: 500;
	--label-large-letter-spacing: 0.00625rem;

	--label-medium-font-size: 0.75rem;
	--label-medium-line-height: 1rem;
	--label-medium-font-weight: 500;
	--label-medium-letter-spacing: 0.03125rem;

	--label-small-font-size: 0.6875rem;
	--label-small-line-height: 1rem;
	--label-small-font-weight: 500;
	--label-small-letter-spacing: 0.03125rem;

	--body-large-font-size: 1rem;
	--body-large-line-height: 1.5rem;
	--body-large-font-weight: 500;
	--body-large-letter-spacing: 0.03125rem;

	--body-medium-font-size: 0.875rem;
	--body-medium-line-height: 1.25rem;
	--body-medium-font-weight: 400;
	--body-medium-letter-spacing: 0.015625rem;

	--body-small-font-size: 0.75rem;
	--body-small-line-height: 1rem;
	--body-small-font-weight: 400;
	--body-small-letter-spacing: 0rem;
}

body .display-large {
	font-size: var(--display-large-font-size);
	line-height: var(--display-large-line-height);
	font-weight: var(--display-large-font-weight);
	letter-spacing: var(--display-large-letter-spacing);
}

body .display-medium {
	font-size: var(--display-medium-font-size);
	line-height: var(--display-medium-line-height);
	font-weight: var(--display-medium-font-weight);
	letter-spacing: var(--display-medium-letter-spacing);
}

body .display-small {
	font-size: var(--display-small-font-size);
	line-height: var(--display-small-line-height);
	font-weight: var(--display-small-font-weight);
	letter-spacing: var(--display-small-letter-spacing);
}

body .headline-large {
	font-size: var(--headline-large-font-size);
	line-height: var(--headline-large-line-height);
	font-weight: var(--headline-large-font-weight);
	letter-spacing: var(--headline-large-letter-spacing);
}

body .headline-medium {
	font-size: var(--headline-medium-font-size);
	line-height: var(--headline-medium-line-height);
	font-weight: var(--headline-medium-font-weight);
	letter-spacing: var(--headline-medium-letter-spacing);
}

body .headline-small {
	font-size: var(--headline-small-font-size);
	line-height: var(--headline-small-line-height);
	font-weight: var(--headline-small-font-weight);
	letter-spacing: var(--headline-small-letter-spacing);
}

body .title-large {
	font-size: var(--title-large-font-size);
	line-height: var(--title-large-line-height);
	font-weight: var(--title-large-font-weight);
	letter-spacing: var(--title-large-letter-spacing);
}

body .title-medium {
	font-size: var(--title-medium-font-size);
	line-height: var(--title-medium-line-height);
	font-weight: var(--title-medium-font-weight);
	letter-spacing: var(--title-medium-letter-spacing);
}

body .title-small {
	font-size: var(--title-small-font-size);
	line-height: var(--title-small-line-height);
	font-weight: var(--title-small-font-weight);
	letter-spacing: var(--title-small-letter-spacing);
}

body .label-large-prominent {
	font-size: var(--label-large-prominent-font-size);
	line-height: var(--label-large-prominent-line-height);
	font-weight: var(--label-large-prominent-font-weight);
	letter-spacing: var(--label-large-prominent-letter-spacing);
}

body .label-large {
	font-size: var(--label-large-font-size);
	line-height: var(--label-large-line-height);
	font-weight: var(--label-large-font-weight);
	letter-spacing: var(--label-large-letter-spacing);
}

body .label-medium {
	font-size: var(--label-medium-font-size);
	line-height: var(--label-medium-line-height);
	font-weight: var(--label-medium-font-weight);
	letter-spacing: var(--label-medium-letter-spacing);
}

body .label-small {
	font-size: var(--label-small-font-size);
	line-height: var(--label-small-line-height);
	font-weight: var(--label-small-font-weight);
	letter-spacing: var(--label-small-letter-spacing);
}

body .body-large {
	font-size: var(--body-large-font-size);
	line-height: var(--body-large-line-height);
	font-weight: var(--body-large-font-weight);
	letter-spacing: var(--body-large-letter-spacing);
}

body .body-medium {
	font-size: var(--body-medium-font-size);
	line-height: var(--body-medium-line-height);
	font-weight: var(--body-medium-font-weight);
	letter-spacing: var(--body-medium-letter-spacing);
}

body .body-small {
	font-size: var(--body-small-font-size);
	line-height: var(--body-small-line-height);
	font-weight: var(--body-small-font-weight);
	letter-spacing: var(--body-small-letter-spacing);
}
