:root {
	--primary-color: #802e34;
	--primary-contrast-color: #ffffff;
	--primary-dark-color: #6d272c;
	--primary-darker-color: #40171a;
	--primary-light-color: #f8ecec;

	--secondary-color: #dddddd;
	--secondary-contrast-color: #ffffff;
	--secondary-dark-color: #ededed;
	--secondary-darker-color: #666666;

	--tertiary-color: #7d5260;
	--tertiary-light-color: #ffffff;

	--error-color: red;
	--error-background: #f9dedc;

	--warning-color: #fbda04;
	--success-color: #0bb70b;
	--info-color: #175cd3;

	--background-color: #f7f7f7;
	--background-darker-color: #f2f2f2;
	--foreground-color: #ffffff;
	--background-contrast-color: #212529;
	--background-secondary-color: #f9f5f0;

	--border-color: #ededed;
	--divider-color: rgba(0, 0, 0, 0.16);

	--disable-color: #ededed;
	--disable-darker-color: #bbbbbb;

	--primary-text-color: #212529;
	--secondary-text-color: #666666;

	--status-active-background: #d1e9ff;
	--status-active-color: #1849a9;
	--status-active-border-color: #84caff;
	--status-completed-background: #dcfae6;
	--status-completed-color: #085d3a;
	--status-inactive-background: #f2f2f2;
	--status-inactive-color: #666666;
	--status-inactive-border-color: #cfcfcf;
	--status-deactivated-background: #f7f7f7;
	--status-deactivated-color: #bbbbbb;
	--status-deactivated-border-color: #dddddd;
}
