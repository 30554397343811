/* stylelint-disable declaration-no-important */
/* To overwrite default style of mat tooltip */
.custom-button-toggle .mat-button-toggle {
	color: var(--secondary-text-color) !important;
}

.custom-button-toggle .mat-button-toggle-checked {
	background-color: var(--primary-light-color) !important;
	color: var(--primary-color) !important;
}

.mat-button-toggle-group {
	border-radius: var(--border-radius-md) !important;
}

.mat-button-toggle-button {
	height: 100%;
}
