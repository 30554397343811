.custom-autocomplete-panel .mat-mdc-autocomplete-panel {
	padding-block: 0;
}

.custom-autocomplete-panel .mdc-list-item__primary-text {
	width: 100%;
}

.custom-autocomplete-panel {
	/* To overwrite default min-width of mat-autocomplete */
	/* stylelint-disable-next-line declaration-no-important */
	min-width: 300px !important;
}
