:root {
	--button-font-size: var(--label-large-font-size);
	--button-line-height: var(--label-large-line-height);
	--button-font-weight: var(--label-large-font-weight);
	--button-letter-spacing: var(--label-large-letter-spacing);

	--button-border-radius: var(--border-radius-md);
	--button-horizontal-padding: var(--space-4);
	--button-height: var(--space-10);
	--button-hover-opacity: 0.7;
}

button {
	background-color: unset;
	border: none;
	cursor: pointer;
}

:is(button, a):is(.filled, .light-filled, .outline, .text, .icon-button) {
	font-size: var(--button-font-size);
	line-height: var(--button-line-height);
	font-weight: var(--button-font-weight);
	letter-spacing: var(--button-letter-spacing);
	text-decoration: none;
	padding-inline: var(--button-horizontal-padding);
	height: var(--button-height);
	cursor: pointer;
	border-radius: var(--button-border-radius);
	transition: 200ms ease-in-out;
	overflow: hidden;
}

a:is(.filled, .light-filled, .outline, .text, .icon-button) {
	text-align: center;
	line-height: var(--button-height);
}

.mat-mdc-icon-button:is(.filled, .outline, .text, .icon-button) .mat-mdc-button-persistent-ripple {
	/* stylelint-disable-next-line declaration-no-important */
	border-radius: var(--button-border-radius) !important;
}
.mat-mdc-icon-button:is(.filled, .outline, .text, .icon-button) {
	--mdc-icon-button-state-layer-size: var(--space-10);
}

/** Link button style. */
button.link {
	padding-inline: var(--button-horizontal-padding);
	height: var(--button-height);
}

/* Filled button style */
button.filled,
a.filled {
	background-color: var(--primary-color);
	border: none;
	color: var(--primary-contrast-color);
}

button.filled:hover,
a.filled:hover {
	background-color: var(--primary-dark-color);
}

button.filled:active,
a.filled:active {
	background-color: var(--primary-darker-color);
}

/* Light filled button style */
button.light-filled,
a.light-filled {
	border: none;
	color: var(--primary-color);
	background-color: #f8ecec;
}

button.light-filled:hover,
a.light-filled:hover {
	background-color: #f8ecec;
}

button.light-filled:active,
a.light-filled:active {
	background-color: #f8ecec;
}

/* Outline button style. */
button.outline,
a.outline {
	background-color: unset;
	border: 1px solid var(--secondary-color);
	color: var(--secondary-text-color);
}

button.outline:hover,
a.outline:hover {
	background-color: var(--secondary-dark-color);
}

button.outline:active,
a.outline:active {
	background-color: var(--secondary-darker-color);
	color: var(--primary-contrast-color);
}

/* Text button style. */
button.text,
a.text {
	background-color: unset;
	border: none;
	border-radius: var(--button-border-radius);
	color: var(--primary-color);
}

a.text:hover {
	color: var(--primary-dark-color);
}

a.text:active {
	color: var(--primary-darker-color);
}

button.filled:disabled,
a.filled:disabled,
button.outline:disabled,
a.outline:disabled,
button.text:disabled,
a.text:disabled,
button.link:disabled,
a.link:disabled {
	opacity: 0.8;
	color: var(--disable-darker-color);
	cursor: not-allowed;
	background-color: unset;
}

button.filled:disabled,
a.filled:disabled {
	background-color: var(--disable-color);
}

button.with-icon {
	display: flex;
	align-items: center;
	gap: var(--space-1);
	padding-left: calc(var(--button-horizontal-padding) - var(--space-1));
}
