// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/material-2-theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

$primary-palette: (
	50: #f4e4e5,
	100: #e3bdbf,
	200: #d09395,
	300: #bd6970,
	400: #ad4751,
	500: #9e3940,
	600: #8e2f37,
	700: #7c262e,
	800: #6b1e25,
	900: #4e1017,
	A100: #f6c3c4,
	A200: #f48f8f,
	A400: #f15b5c,
	A700: #ef4343,
	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: white,
		400: white,
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		A100: rgba(black, 0.87),
		A200: white,
		A400: white,
		A700: white,
	),
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$web-primary: mat.m2-define-palette($primary-palette);
$web-accent: mat.m2-define-palette(mat.$m2-gray-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$web-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $web-primary,
			accent: $web-accent,
			warn: $web-warn,
		),
		density: -3,
	)
);

// Some components are really small when apply density decreasing. So we will make it to be the default density in here.
body {
	@include mat.table-density(0);
	@include mat.tabs-density(0);
	@include mat.chips-density(0);
	@include mat.checkbox-density(0);
	@include mat.radio-density(0);
}

.dense-1 {
	@include mat.all-component-densities(-1);
}

.dense-2 {
	@include mat.all-component-densities(-2);
}

.dense-3 {
	@include mat.all-component-densities(-3);
}

.dense-4 {
	@include mat.all-component-densities(-4);
}

.dense-5 {
	@include mat.all-component-densities(-5);
}

.dense-6 {
	@include mat.all-component-densities(-6);
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-theme);
