.table-with-hover .mat-mdc-row:hover {
	background-color: var(--background-color);
	cursor: pointer;
	transition: 0.2s ease;
}

.mat-mdc-table .mdc-data-table__cell,
.mat-mdc-table .mdc-data-table__header-cell {
	padding: 0 var(--space-2) 0 var(--space-2);
}

.mat-mdc-table .mat-mdc-header-row {
	height: 48px;
}

.mat-mdc-table .mat-mdc-row {
	height: 40px;
}

.mat-mdc-paginator {
	border-top-color: var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
	border-top-width: var(--mat-table-row-item-outline-width, 1px);
	border-top-style: solid;
}
