.mat-mdc-form-field {
	--mdc-outlined-text-field-container-shape: var(--space-2);

	background-color: var(--foreground-color);
	border-radius: var(--border-radius-md);
}

.mat-mdc-form-field-infix {
	display: flex;
	align-items: center;
}

/* Custom background color for form field
Ref: https://stackoverflow.com/a/77667932/23352644 */

.form-field-with-contrast-color .mdc-text-field--outlined .mdc-notched-outline {
	z-index: 0;
}
.form-field-with-contrast-color .mdc-notched-outline__notch,
.form-field-with-contrast-color .mdc-notched-outline__leading,
.form-field-with-contrast-color .mdc-notched-outline__trailing {
	background-color: var(--primary-contrast-color);
}

.mat-mdc-form-field-required-marker {
	color: var(--error-color);
}
