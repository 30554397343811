.xng-breadcrumb-root {
	font-size: var(--body-medium-font-size);
	line-height: var(--body-medium-line-height);
	font-weight: var(--body-medium-font-weight);
	letter-spacing: var(--body-medium-letter-spacing);
}

.xng-breadcrumb-item:last-child {
	color: var(--secondary-text-color);
}
