.mat-mdc-dialog-container {
	--mdc-dialog-container-shape: var(--border-radius-md);
	--mdc-dialog-supporting-text-color: var(--primary-text-color);
}

.mat-mdc-dialog-container .mat-mdc-dialog-title.mdc-dialog__title {
	font-size: var(--headline-small-font-size);
	line-height: var(--headline-small-line-height);
	font-weight: var(--headline-small-font-weight);
	letter-spacing: var(--headline-small-letter-spacing);
	padding: var(--space-7) var(--space-6);
	margin: 0;
}

.mat-mdc-dialog-container .mat-mdc-dialog-title::before {
	content: none;
}

.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content.mdc-dialog__content {
	padding: var(--space-6) var(--space-10);
}

.dialog-title-with-close-button {
	/* stylelint-disable-next-line declaration-no-important */
	display: flex !important;
	justify-content: space-between;
	gap: var(--space-2);
}
