:root {
	--container-padding: var(--page-horizontal-padding);
	--container-max-width: var(--page-content-max-width-with-padding);
}

.container {
	padding-inline: var(--container-padding);
	max-width: var(--container-max-width);
}

.container-center {
	padding-inline: var(--container-padding);
	max-width: var(--container-max-width);
	margin: auto;
}

.truncate-1 {
	-webkit-line-clamp: 1;
	line-clamp: 1;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
}

.truncate-2 {
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
}
