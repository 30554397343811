.meic-loading {
	--spinner-color: white;
	--spinner-background-color: rgba(0, 0, 0, 0.3);
	--spinner-size: var(--space-3);

	cursor: progress;
	position: relative;
	overflow: hidden;
}

.meic-loading.meic-loading-inherit-color {
	--spinner-color: currentColor;
	--spinner-background-color: transparent;
}

.meic-loading::after {
	position: absolute;
	top: calc(50% - var(--spinner-size));
	left: calc(50% - var(--spinner-size));
	content: " ";
	display: block;
	width: var(--spinner-size);
	height: var(--spinner-size);
	margin: var(--space-1);
	border-radius: 50%;
	border: 2px solid var(--spinner-color);
	border-color: var(--spinner-color) transparent var(--spinner-color) transparent;
	animation: meic-loading-spin 1.2s ease-in-out infinite;
	z-index: 1;
}

.meic-loading::before {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: var(--spinner-background-color);
	animation: fadein 200ms ease-in;
	/* To balance loader positioning on flexbox */
	content: "";
}

@keyframes meic-loading-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
