[class*="Main__Inner"] {
	/* Overwrite the default max-width of es layout. */
	/* stylelint-disable-next-line declaration-no-important */
	max-width: var(--page-content-max-width-with-padding) !important;
}

.es-grid-layout {
	/* Overwrite grid behavior of es layout. */
	/* stylelint-disable-next-line declaration-no-important */
	grid-template-columns: repeat(auto-fill, minmax(340px, 1fr)) !important;
}
